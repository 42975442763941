import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"javascript-i", url:"#", title:"Introducere in Javascript", subtitle:""},

        {id:"javascript-overview", url:"/javascript/overview", title:"Generalitati", subtitle:"", parent: "javascript-i"},
        {id:"javascript-variables", url:"/javascript/variables", title:"Variabile", subtitle:"", parent: "javascript-i"},
        {id:"javascript-data-types", url:"/javascript/data-types", title:"Tipuri de date primitive", subtitle:"", parent: "javascript-i"},
        {id:"javascript-type-conversion", url:"/javascript/type-conversion", title:"Conversi de tip", subtitle:"", parent: "javascript-i"},
        {id:"javascript-complex-data-types", url:"/javascript/complex-data-types", title:"Tipuri de date complexe", subtitle:"", parent: "javascript-i"},
        {id:"javascript-comments", url:"/javascript/comments", title:"Comentarii", subtitle:"", parent: "javascript-i"},
        {id:"javascript-operators", url:"/javascript/operators", title:"Operatori", subtitle:"", parent: "javascript-i"},
        {id:"javascript-dialogs", url:"/javascript/dialogs", title:"Casete de dialog", subtitle:"", parent: "javascript-i"},
        {id:"javascript-if", url:"/javascript/if", title:"Instructiunea if", subtitle:"", parent: "javascript-i"},
        {id:"javascript-conditional-operator", url:"/javascript/conditional-operator", title:"Operarator conditional", subtitle:"", parent: "javascript-i"},
        {id:"javascript-switch", url:"/javascript/switch", title:"Instructiunea switch", subtitle:"", parent: "javascript-i"},
        {id:"javascript-while", url:"/javascript/while", title:"Instructiunea while", subtitle:"", parent: "javascript-i"},
        {id:"javascript-for", url:"/javascript/for", title:"Instructiunea for", subtitle:"", parent: "javascript-i"},
        {id:"javascript-functions", url:"/javascript/functions", title:"Functii", subtitle:"", parent: "javascript-i"},
        {id:"javascript-errors", url:"/javascript/errors", title:"Gestiunea erorilor si exceptiilor", subtitle:"", parent: "javascript-i"},
        {id:"javascript-debugging", url:"/javascript/debugging", title:"Depanare/debugging", subtitle:"", parent: "javascript-i"},

        {id:"javascript-util", url:"#", title:"Util", subtitle:""},
        {id:"javascript-pushstate", url:"/javascript/pushstate", title:"history.pushState", subtitle:"", parent: "javascript-util"},

    ];

    static indexUrl = "/javascript/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Introducere în Javascript
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;