import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [
        {id:"liferay", url:"#", title:"Introducere în Liferay", subtitle:""},

        {id:"liferay-setup-intellij", url:"/liferay/setup-intellij", title:"Setup in IntelliJ", subtitle:"", parent: "liferay"},
        {id:"liferay-blade", url:"/liferay/blade", title:"Introducere în Blade", subtitle:"", parent: "liferay"},
        {id:"liferay-first-portlet", url:"/liferay/first-portlet", title:"Creare primul modul: portlet", subtitle:"", parent: "liferay"},
        {id:"liferay-deploy-module", url:"/liferay/deploy-module", title:"Deploy modul", subtitle:"", parent: "liferay"},
        {id:"liferay-change-ports", url:"/liferay/change-ports", title:"Schimbare porturi", subtitle:"", parent: "liferay"},
        {id:"liferay-osgi", url:"/liferay/osgi", title:"OSGi", subtitle:"", parent: "liferay"},
        {id:"liferay-standards", url:"/liferay/standards", title:"Standarde suportate", subtitle:"", parent: "liferay"},

        {id:"liferay-theme", url:"#", title:"Teme", subtitle:""},

        {id:"liferay-first-theme", url:"/liferay/first-theme", title:"Crearea unei teme", subtitle:"", parent: "liferay-theme"},
        {id:"liferay-anatomy-theme", url:"/liferay/anatomy-theme", title:"Anatomia unei teme", subtitle:"", parent: "liferay-theme"},
        {id:"liferay-footer-theme", url:"/liferay/footer-theme", title:"Footer", subtitle:"", parent: "liferay-theme"},
        {id:"liferay-header-theme", url:"/liferay/header-theme", title:"Header (SearchBar, Autentificare, Creare cont)", subtitle:"", parent: "liferay-theme"},
        {id:"liferay-navigation-theme", url:"/liferay/navigation-theme", title:"Navigation / Meniu", subtitle:"", parent: "liferay-theme"},
        {id:"liferay-login-popup", url:"/liferay/login-popup", title:"Ecran de login de tip popup", subtitle:"", parent: "liferay-theme"},


        {id:"liferay-portlet", url:"#", title:"Portleti", subtitle:""},
        {id:"liferay-portlet-mvc-render-command", url:"/liferay/portlet-mvc-render-command", title:"MVCRenderCommand", subtitle:"", parent: "liferay-portlet"},
        {id:"liferay-redirect", url:"/liferay/redirect", title:"MVCRenderCommand (exemplu): Redirect catre alta pagina din cadrul aceluiasi portlet", subtitle:"", parent: "liferay-portlet"},
        {id:"liferay-portlet-mvc-action-command", url:"/liferay/portlet-mvc-action-command", title:"MVCActionCommand", subtitle:"", parent: "liferay-portlet"},
        {id:"liferay-save-action", url:"/liferay/save-action", title:"MVCActionCommand: Exemplu 2", subtitle:"", parent: "liferay-portlet"},
        {id:"liferay-portlet-lifecycle", url:"/liferay/portlet-lifecycle", title:"Ciclul de viata a unui portlet", subtitle:"", parent: "liferay-portlet"},
        {id:"liferay-portlet-form", url:"/liferay/portlet-form", title:"Formulare", subtitle:"", parent: "liferay-portlet"},
        {id:"liferay-portlet-form-validation", url:"/liferay/portlet-form-validation", title:"Validare Formulare", subtitle:"", parent: "liferay-portlet"},
        {id:"liferay-portlet-search-container", url:"/liferay/portlet-search-container", title:"Search Container", subtitle:"", parent: "liferay-portlet"},
        {id:"liferay-portlet-search-container-delta values", url:"/liferay/portlet-search-container-delta-values", title:"Search Container: Modificare lista de valori delta", subtitle:"", parent: "liferay-portlet"},

        {id:"liferay-clay-management-toolbar", url:"/liferay/clay-management-toolbar", title:"Cautare in Search Container (clay:management-toolbar)", subtitle:"", parent: "liferay-portlet"},
        {id:"liferay-portlet-configuration", url:"/liferay/portlet-configuration", title:"Configurare portlet", subtitle:"", parent: "liferay-portlet"},
        {id:"liferay-portlet-instanceable", url:"/liferay/portlet-instanceable", title:"Proprietatea com.liferay.portlet.instanceable", subtitle:"", parent: "liferay-portlet"},
        {id:"liferay-portlet-image", url:"/liferay/portlet-image", title:"Imagine in portlet", subtitle:"", parent: "liferay-portlet"},

        {id:"liferay-override", url:"#", title:"Inlocuire / Suprascriere / Override", subtitle:""},
        {id:"liferay-override-jsp", url:"/liferay/override-jsp", title:"Inlocuire / Suprascriere JSP folosind OSGi Fragment [ex: Login]", subtitle:"", parent: "liferay-override"},
        {id:"liferay-custom-jsp-bag", url:"/liferay/custom-jsp-bag", title:"Inlocuire / Suprascriere JSP folosind Custom JSP Bag [ex: verify_email_address.jsp]", subtitle:"", parent: "liferay-override"},
        {id:"liferay-custom-jsp-bag-vs-osgi-fragments", url:"/liferay/custom-jsp-bag-vs-osgi-fragments", title:"Custom JSP Bag vs OSGi Fragments", subtitle:"", parent: "liferay-override"},
        {id:"liferay-portlet-override-mvc-action-command", url:"/liferay/portlet-override-mvc-action-command", title:"Suprascriere comanda de tip MVC Action", subtitle:"", parent: "liferay-override"},
        {id:"liferay-portlet-override-mvc-render-command", url:"/liferay/portlet-override-mvc-render-command", title:"Suprascriere comanda de tip MVC Render", subtitle:"", parent: "liferay-override"},

        {id:"liferay-forms", url:"#", title:"Formulare folosind Forms", subtitle:""},
        {id:"liferay-forms-validation", url:"/liferay/portlet-forms-validation", title:"Validare formulare", subtitle:"", parent: "liferay-forms"},
        {id:"liferay-data-provider", url:"/liferay/data-provider", title:"Populare lista cu un Data Provider", subtitle:"", parent: "liferay-forms"},
        {id:"liferay-custom-data-provider", url:"/liferay/custom-data-provider", title:"Populare campuri cu un Data Provider personalizat", subtitle:"", parent: "liferay-forms"},
        {id:"liferay-liferay-forms-ddm-storage-adapter", url:"/liferay/liferay-forms-ddm-storage-adapter", title:"Liferay Forms: DDM Storage Adapter", subtitle:"", parent: "liferay-forms"},
        {id:"liferay-remove-limit-30-fields", url:"/liferay/remove-limit-30-fields", title:"Stergere limitare 30 de campuri", subtitle:"", parent: "liferay-forms"},
        {id:"liferay-prepopulation-fields", url:"/liferay/prepopulation-fields", title:"Prepopulare campuri", subtitle:"", parent: "liferay-forms"},
        {id:"liferay-custom-field", url:"/liferay/custom-field", title:"Campuri custom / Custom Field Forms", subtitle:"", parent: "liferay-forms"},
        {id:"liferay-validation-custom-field", url:"/liferay/validation-custom-field", title:"Validare campuri custom / Validare Custom Field Forms", subtitle:"", parent: "liferay-forms"},
        {id:"liferay-app-config-jsp", url:"/liferay/app-config-jsp", title:"App Config - Jsp", subtitle:"", parent: "liferay-forms"},
        {id:"liferay-app-config-react", url:"/liferay/app-config-react", title:"App Config - React", subtitle:"", parent: "liferay-forms"},

        {id:"liferay-localization", url:"#", title:"Localizare", subtitle:""},
        {id:"liferay-localization-intro", url:"/liferay/localization-intro", title:"Localizare", subtitle:"", parent: "liferay-localization"},
        {id:"liferay-changing-language-translations", url:"/liferay/changing-language-translations", title:"Schimbarea traducerilor de limbă", subtitle:"", parent: "liferay-localization"},

        {id:"liferay-build", url:"#", title:"Servicii", subtitle:""},

        {id:"liferay-build-service", url:"/liferay/build-service", title:"Generare servicii: model si tabele", subtitle:"", parent: "liferay-build"},
        {id:"liferay-build-service-web", url:"/liferay/build-service-web", title:"Servicii web JAX-RS", subtitle:"", parent: "liferay-build"},
        {id:"liferay-build-service-web-public", url:"/liferay/build-service-web-public", title:"Servicii web JAX-RS publice", subtitle:"", parent: "liferay-build"},
        {id:"liferay-service-web-mock", url:"/liferay/service-web-mock", title:"Servicii web JAX-RS Mock", subtitle:"", parent: "liferay-build"},
        {id:"liferay-service-web-test-ui", url:"/liferay/service-web-test-ui", title:"Servicii web JAX-RS Test UI", subtitle:"", parent: "liferay-build"},

        {id:"liferay-resteasy-httpcomponents", url:"/liferay/resteasy-httpcomponents", title:"resteasy vs httpcomponents", subtitle:"", parent: "liferay-build"},

        {id:"liferay-share-i18n", url:"#", title:"Partajare chei de limba / Language Key (i18n)", subtitle:""},

        {id:"liferay-share-language-key-portlet", url:"/liferay/share-language-key-portlet", title:"Partajare chei intre portleti", subtitle:"", parent: "liferay-share-i18n"},
        {id:"liferay-share-language-key-theme", url:"/liferay/share-language-key-theme", title:"Partajare chei cu o tema", subtitle:"", parent: "liferay-share-i18n"},

        {id:"liferay-keycloak-parent", url:"#", title:"Integrare cu Keycloak", subtitle:""},

        {id:"liferay-keycloak", url:"/liferay/keycloak", title:"Instalare si configurare Keycloak", subtitle:"(pas cu pas, urmand 31 de imagini)", parent: "liferay-keycloak-parent"},
        {id:"liferay-keycloak-integration", url:"/liferay/keycloak-integration", title:"Integrare cu Keycloak", subtitle:"(pas cu pas, urmand 12 de imagini + rezolvare posibile 2 probleme)", parent: "liferay-keycloak-parent"},
        {id:"liferay-keycloak-slo", url:"/liferay/keycloak-slo", title:"SLO (Single Logout)", subtitle:"", parent: "liferay-keycloak-parent"},

        {id:"liferay-elasticsearch-parent", url:"#", title:"Elasticsearch", subtitle:""},
        {id:"liferay-elasticsearch", url:"/liferay/elasticsearch", title:"Elasticsearch", subtitle:"", parent: "liferay-elasticsearch-parent"},

        {id:"liferay-custom", url:"#", title:"Customizari", subtitle:""},
        {id:"liferay-expando-bridge", url:"/liferay/expando-bridge", title:"Gestiunea campurilor custom folosind Expando Bridge", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-model-listener", url:"/liferay/model-listener", title:"Model Listener", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-service-wrapper", url:"/liferay/service-wrapper", title:"Service Wrapper", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-jsp", url:"/liferay/jsp", title:"JSP", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-i18n", url:"/liferay/i18n", title:"i18n", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-css", url:"/liferay/css", title:"CSS", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-javascript", url:"/liferay/javascript", title:"JavaScript", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-react", url:"/liferay/react", title:"React", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-tomcat-ssl", url:"/liferay/tomcat-ssl", title:"Configurare Tomcat HTTPs", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-apache-ssl", url:"/liferay/apache-ssl", title:"Apache HTTPd cu SSL in fata ca proxy", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-application-startup-events", url:"/liferay/application-startup-events", title:"Evenimente de pornire a aplicației", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-jquery", url:"/liferay/jquery", title:"JQuery", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-jquery-activation", url:"/liferay/jquery-activation", title:"Activare JQuery (de exemplu a functiona modale bootstrap)", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-fire-and-on-event", url:"/liferay/fire-and-on-event", title:"Liferay.fire() si Liferay.on()", subtitle:"", parent: "liferay-custom"},

        {id:"liferay-configuration-instance-settings", url:"/liferay/configuration-instance-settings", title:"Creare configurare personalizata in System Settings", subtitle:"", parent: "liferay-custom"},
        {id:"liferay-custom-login", url:"/liferay/custom-login", title:"Logare personalizata", subtitle:"", parent: "liferay-custom"},

        {id:"liferay-email", url:"#", title:"Email", subtitle:""},
        {id:"liferay-email-config", url:"/liferay/email-config", title:"Configurare E-mail", subtitle:"", parent: "liferay-email"},
        {id:"liferay-fake-smtp-ethereal-email-config", url:"/liferay/fake-smtp-ethereal-email-config", title:"Fake SMTP Service: ethereal", subtitle:"", parent: "liferay-email"},
        {id:"liferay-email-settings", url:"/liferay/email-settings", title:"Setari E-mail UI: Personalizare sabloane notificari", subtitle:"", parent: "liferay-email"},

        {id:"liferay-widget", url:"#", title:"Widget-uri", subtitle:""},
        {id:"liferay-widget-site-map", url:"/liferay/widget-site-map", title:"Site map", subtitle:"", parent: "liferay-widget"},
        {id:"liferay-widget-calendar", url:"/liferay/widget-calendar", title:"Calendar", subtitle:"", parent: "liferay-widget"},

        {id:"liferay-util", url:"#", title:"Util", subtitle:""},
        {id:"liferay-db", url:"/liferay/db", title:"Despre baza de date Liferay", subtitle:"", parent: "liferay-util"},
        {id:"liferay-2fa", url:"/liferay/2fa", title:"Autentificare cu 2 factori (2FA)", subtitle:"", parent: "liferay-util"},
        {id:"liferay-modal-ajax", url:"/liferay/modal-ajax", title:"Afisare informatii obtinute prin apel AJAX intr-o modala", subtitle:"", parent: "liferay-util"},
        {id:"liferay-render-html-form", url:"/liferay/render-html-form", title:"Afisare html randat dintr-o Liferay Form", subtitle:"", parent: "liferay-util"},
        {id:"liferay-serializing-form", url:"/liferay/serializing-form", title:"Serializare forma Liferay Form", subtitle:"", parent: "liferay-util"},
        {id:"liferay-deserializing-form", url:"/liferay/deserializing-form", title:"Deserializare forma Liferay Form", subtitle:"", parent: "liferay-util"},
        {id:"liferay-redirect-other-portlet", url:"/liferay/redirect-other-portlet", title:"Redirect cu parametrii catre alt portlet", subtitle:"", parent: "liferay-util"},
        {id:"liferay-upload-files-ajax", url:"/liferay/upload-files-ajax", title:"Upload Fisiere folosind Ajax", subtitle:"", parent: "liferay-util"},
        {id:"liferay-workflow", url:"/liferay/workflow", title:"Workflow", subtitle:"", parent: "liferay-util"},
        {id:"liferay-dlstoreutil", url:"/liferay/dlstoreutil", title:"Stocare fisiere", subtitle:"", parent: "liferay-util"},
        {id:"liferay-timeout-session", url:"/liferay/timeout-session", title:"Modificare Timeout Session", subtitle:"", parent: "liferay-util"},
        {id:"liferay-friendly-url", url:"/liferay/friendly-url", title:"Friendly URL", subtitle:"", parent: "liferay-util"},
        {id:"liferay-scheduler", url:"/liferay/scheduler", title:"Scheduler (BaseMessageListener)", subtitle:"", parent: "liferay-util"},
        {id:"liferay-api-explorer", url:"/liferay/api-explorer", title:"Liferay API Explorer", subtitle:"", parent: "liferay-util"},
        {id:"liferay-company-login-prepopulate-domain", url:"/liferay/company-login-prepopulate-domain", title:"Stergere @domain din ecranul de logare", subtitle:"", parent: "liferay-util"},
        {id:"liferay-upgrade-processes", url:"/liferay/upgrade-processes", title:"Introducere in Upgrade Processes", subtitle:"", parent: "liferay-util"},
        {id:"liferay-disable-change-password-first-login", url:"/liferay/disable-change-password-first-login", title:"Dezactivare schimbare parola la prima logare", subtitle:"", parent: "liferay-util"},
        {id:"liferay-terms-of-use", url:"/liferay/terms-of-use", title:"Termeni de folosire / Terms of use", subtitle:"", parent: "liferay-util"},
        {id:"liferay-hide-commerce", url:"/liferay/hide-commerce", title:"Ascundere Commerce", subtitle:"", parent: "liferay-util"},
        {id:"liferay-disable-password-reminder-questions-first-login", url:"/liferay/disable-password-reminder-questions-first-login", title:"Dezactivarea întrebărilor de reamintire a parolei la prima conectare a utilizatorilor", subtitle:"", parent: "liferay-util"},
        {id:"liferay-adding-custom-password-reminder-questions", url:"/liferay/adding-custom-password-reminder-questions", title:"Adaugarea întrebărilor personalizate de reamintire a parolei", subtitle:"", parent: "liferay-util"},
        {id:"liferay-reindex", url:"/liferay/reindex", title:"Reindexare", subtitle:"", parent: "liferay-util"},

        {id:"liferay-webcontent", url:"#", title:"Proiecte", subtitle:""},
        {id:"liferay-webcontent-link-url", url:"/liferay/webcontent-link-url", title:"Link catre URL", subtitle:"", parent: "liferay-webcontent"},

        {id:"liferay-projects", url:"#", title:"Proiecte", subtitle:""},
        {id:"liferay-workflow-page", url:"/liferay/workflow-page", title:"Workflow Page", subtitle:"", parent: "liferay-projects"},

        {id:"liferay-components", url:"#", title:"Componente", subtitle:""},
        {id:"liferay-tab", url:"/liferay/tab", title:"Exemplu componenta tab", subtitle:"", parent: "liferay-components"},

        {id:"liferay-site-initializer-root", url:"#", title:"Site initializer", subtitle:""},
        {id:"liferay-intro-site-initializers", url:"/liferay/intro-site-initializers", title:"Introducere in Site Initializers(SI) [1]: Roluri, Fragmente, (Creare SI)", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-intro-site-initializers-2", url:"/liferay/intro-site-initializers-2", title:"Introducere in Site Initializers(SI) [2]: Custom Fields, Documente, (LPS-165482 - Actualizare SI)", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-intro-site-initializers-3", url:"/liferay/intro-site-initializers-3", title:"Introducere in Site Initializers(SI) [3]: Widget Templates", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-intro-site-initializers-4", url:"/liferay/intro-site-initializers-4", title:"Introducere in Site Initializers(SI) [4]: Style Books", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-intro-site-initializers-5", url:"/liferay/intro-site-initializers-5", title:"Introducere in Site Initializers(SI) [5]: Layout Set", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-intro-site-initializers-6", url:"/liferay/intro-site-initializers-6", title:"Introducere in Site Initializers(SI) [6]: Master Pages, Pages Templates, Pages, Page Content", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-intro-site-initializers-7", url:"/liferay/intro-site-initializers-7", title:"Introducere in Site Initializers(SI) [7]: Segments Experiences", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-intro-site-initializers-8", url:"/liferay/intro-site-initializers-8", title:"Introducere in Site Initializers(SI) [8]: Site Navigation Menus", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-intro-site-initializers-9", url:"/liferay/intro-site-initializers-9", title:"Introducere in Site Initializers(SI) [9]: I18n texte in html-uri", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-intro-site-initializers-10", url:"/liferay/intro-site-initializers-10", title:"Introducere in Site Initializers(SI) [10]: Portlet pe o un layout/pagina", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-intro-site-initializers-11", url:"/liferay/intro-site-initializers-11", title:"Introducere in Site Initializers(SI) [11]: Permisiuni pentru un layout/pagina", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-intro-site-initializers-12", url:"/liferay/intro-site-initializers-12", title:"Introducere in Site Initializers(SI) [12]: Articole/Web content", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-intro-site-initializers-13", url:"/liferay/intro-site-initializers-13", title:"Introducere in Site Initializers(SI) [13]: Exemplu: Configurare structuri", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-intro-site-initializers-13", url:"/liferay/intro-site-initializers-14", title:"Configurare instanta (URL acasa, URL delogare, Pagina implicita)", subtitle:"", parent: "liferay-site-initializer-root"},
        {id:"liferay-init-with-si", url:"/liferay/init-with-si", title:"Initializare Portal cu Site Initializer", subtitle:"", parent: "liferay-site-initializer-root"},

        {id:"liferay-design", url:"#", title:"Design", subtitle:""},
        {id:"liferay-design-book-styles", url:"/liferay/design-book-styles", title:"Book Styles", subtitle:"", parent: "liferay-design"},
        {id:"liferay-design-fragments", url:"/liferay/design-fragments", title:"Fragments", subtitle:"", parent: "liferay-design"},

        {id:"liferay-integration", url:"#", title:"Integrari", subtitle:""},
        {id:"liferay-chatbot", url:"/liferay/chatbot", title:"Chatbot", subtitle:"", parent: "liferay-integration"},
        {id:"liferay-userway", url:"/liferay/userway", title:"Accesibilitate: userway", subtitle:"", parent: "liferay-integration"},
        {id:"liferay-cookies", url:"/liferay/cookies", title:"Cookies: jquery.ihavecookies.min.js", subtitle:"", parent: "liferay-integration"},
        {id:"liferay-backtotop", url:"/liferay/backtotop", title:"Back to top", subtitle:"", parent: "liferay-integration"},
        {id:"liferay-ga", url:"/liferay/ga", title:"Google Analytics", subtitle:"", parent: "liferay-integration"},

        {id:"liferay-problems", url:"#", title:"Probleme", subtitle:""},
        {id:"liferay-debug", url:"/liferay/debug", title:"Debug", subtitle:"", parent: "liferay-problems"},
        {id:"liferay-forms-cannot-be-imported", url:"/liferay/forms-cannot-be-imported", title:"LAR cannot be imported", subtitle:"", parent: "liferay-problems"},
        {id:"liferay-invalid-cen-header", url:"/liferay/invalid-cen-header", title:"Invalid CEN header", subtitle:"", parent: "liferay-problems"},

        {id:"liferay-anexa", url:"#", title:"Anexa", subtitle:""},
        {id:"liferay-cleaning-up", url:"/liferay/cleaning-up", title:"Curatare instanta Liferay", subtitle:"", parent: "liferay-anexa"},
        {id:"liferay-data-access", url:"/liferay/data-access", title:"DataAccess: accesare baza de date JDBC", subtitle:"", parent: "liferay-anexa"},
        {id:"liferay-anexa-tips", url:"/liferay/anexa-tips", title:"Tips 1", subtitle:"", parent: "liferay-anexa"},
        {id:"liferay-anexa-tips2", url:"/liferay/anexa-tips2", title:"Tips 2", subtitle:"", parent: "liferay-anexa"},
        {id:"liferay-anexa-tips3", url:"/liferay/anexa-tips3", title:"Tips 3", subtitle:"", parent: "liferay-anexa"},
        {id:"liferay-anexa-fragments", url:"/liferay/anexa-fragments", title:"Fragmente de cod", subtitle:"", parent: "liferay-anexa"},
        {id:"liferay-new-74", url:"/liferay/new-74", title:"Ce este nou in Liferay 7.4?", subtitle:"", parent: "liferay-anexa"},
        {id:"liferay-caracteristici", url:"/liferay/caracteristici", title:"Caracteristici Liferay DXP", subtitle:"", parent: "liferay-anexa"},


        {id:"liferay-anexa-2", url:"#", title:"Anexa 2", subtitle:""},
        {id:"liferay-anexa-create-project", url:"/liferay/anexa/create-project", title:"Creare proiect (Liferay CE 7.4.3.120-ga120)", subtitle:"", parent: "liferay-anexa-2"},
        {id:"liferay-anexa-job-scheduler", url:"/liferay/anexa/job-scheduler", title:"Job Scheduler", subtitle:"", parent: "liferay-anexa-2"},
        {id:"liferay-anexa-custom-config-system-settings", url:"/liferay/anexa/custom-config-system-settings", title:"Configurari personalitate in System Settings", subtitle:"", parent: "liferay-anexa-2"},
        {id:"liferay-anexa-structures", url:"/liferay/anexa/structures", title:"Structures, Templates, Display Page Templates, Collections, Asset Publisher, Page Template", subtitle:"", parent: "liferay-anexa-2"},
        {id:"liferay-anexa-service-builder", url:"/liferay/anexa/service-builder", title:"Service Builder", subtitle:"", parent: "liferay-anexa-2"},
        {id:"liferay-anexa-portlet", url:"/liferay/anexa/portlet", title:"Portlet", subtitle:"", parent: "liferay-anexa-2"},
        {id:"liferay-anexa-upgrade-steps", url:"/liferay/anexa/upgrade-steps", title:"Upgrade steps", subtitle:"", parent: "liferay-anexa-2"},
        {id:"liferay-anexa-activators", url:"/liferay/anexa/activators", title:"Activators", subtitle:"", parent: "liferay-anexa-2"},
        {id:"liferay-anexa-plo", url:"/liferay/anexa/plo", title:"PLO", subtitle:"", parent: "liferay-anexa-2"},

        {id:"liferay-anexa-servlet-filter", url:"/liferay/anexa/servlet-filter", title:"Servlet Filter", subtitle:"", parent: "liferay-anexa-2"},
        {id:"liferay-anexa-portlet-filter", url:"/liferay/anexa/portlet-filter", title:"Portlet Filter", subtitle:"", parent: "liferay-anexa-2"},
    ]

    static indexUrl = "/liferay/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Liferay
                </div>

                {TocHelper.display(IndexContent.content)}

            </div>
        );
    }
}

export default IndexContent;