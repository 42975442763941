import {useRoutes} from "react-router-dom";
import React from "react";
import LiferayHomeContent from "./IndexContent";
import PrivateRoute from "../../security/PrivateRoute";
import SetupIntelliJContent from "./SetupIntelliJContent";
import FirstPortletContent from "./FirstPortletContent";
import DeployModuleContent from "./DeployModuleContent";
import CreateThemesContent from "./CreateThemesContent";
import AnatomyThemeContent from "./AnatomyThemeContent";
import FooterThemeContent from "./FooterThemeContent";
import HeaderThemeContent from "./HeaderThemeContent";
import NavigationThemeContent from "./NavigationThemeContent";
import LoginPopupThemeContent from "./LoginPopupThemeContent";
import PortletConfigurationContent from "./PortletConfigurationContent";
import ShareLanguageKeyPortletContent from "./ShareLanguageKeyPortletContent";
import ShareLanguageKeyThemeContent from "./ShareLanguageKeyThemeContent";
import KeycloakLiferayContent from "./KeycloakLiferayContent";
import KeycloakIntegrationLiferayContent from "./KeycloakIntegrationLiferayContent";
import KeycloakSLOLiferayContent from "./KeycloakSLOLiferayContent";
import ChangePortsLiferayContent from "./ChangePortsLiferayContent";
import ElasticsearchLiferayContent from "./ElasticsearchLiferayContent";
import OsgiLiferayContent from "./OsgiLiferayContent";
import BuildServiceContent from "./BuildServiceContent";
import ExpandoBridgeLiferayContent from "./ExpandoBridgeLiferayContent";
import ModelListenersLiferayContent from "./ModelListenersLiferayContent";
import ServiceWrapperLiferayContent from "./ServiceWrapperLiferayContent";
import JSPLiferayContent from "./JSPLiferayContent";
import I18nLiferayContent from "./I18nLiferayContent";
import ServiceWebLiferayContent from "./ServiceWebLiferayContent";
import MVCRenderCommandPortletContent from "./MVCRenderCommandPortletContent";
import MVCActionCommandPortletContent from "./MVCActionCommandPortletContent";
import OverrideMVCActionCommandPortletContent from "./OverrideMVCActionCommandPortletContent";
import OverrideMVCRenderCommandPortletContent from "./OverrideMVCRenderCommandPortletContent";
import InstanceableLiferayContent from "./InstanceableLiferayContent";
import LifecyclePortletContent from "./LifecyclePortletContent";
import FormPortletContent from "./FormPortletContent";
import SearchContainerPortletContent from "./SearchContainerPortletContent";
import EmailConfigPortletContent from "./EmailConfigPortletContent";
import ValidateFormPortletContent from "./ValidateFormPortletContent";
import FormsValidationContent from "./FormsValidationContent";
import LocalizationLiferayContent from "./LocalizationLiferayContent";
import CssLiferayContent from "./CssLiferayContent";
import JavascriptLiferayContent from "./JavascriptLiferayContent";
import ReactLiferayContent from "./ReactLiferayContent";
import TomcatSSLLiferayContent from "./TomcatSSLLiferayContent";
import TipsLiferayContent from "./TipsLiferayContent";
import Tips2LiferayContent from "./Tips2LiferayContent";
import Tips3LiferayContent from "./Tips3LiferayContent";
import FragmentsLiferayContent from "./FragmentsLiferayContent";
import SiteMapWidgetLiferayContent from "./SiteMapWidgetLiferayContent";
import CalendarWidgetLiferayContent from "./CalendarWidgetLiferayContent";
import StandardsLiferayContent from "./StandardsLiferayContent";
import Auth2FALiferayContent from "./Auth2FALiferayContent";
import ApplicationStartupEventsLiferayContent from "./ApplicationStartupEventsLiferayContent";
import AjaxModalLiferayContent from "./AjaxModalLiferayContent";
import RenderHtmlFormLiferayContent from "./RenderHtmlFormLiferayContent";
import SerializingFormsLiferayContent from "./SerializingFormsLiferayContent";
import DeserializingFormsLiferayContent from "./DeserializingFormsLiferayContent";
import WorkflowLiferayContent from "./WorkflowLiferayContent";
import JQueryLiferayContent from "./JQueryLiferayContent";
import WorkflowPageLiferayContent from "./WorkflowPageLiferayContent";
import RedirectOtherPortletLiferayContent from "./RedirectOtherPortletLiferayContent";
import UploadFilesAjaxLiferayContent from "./UploadFilesAjaxLiferayContent";
import BladeLiferayContent from "./BladeLiferayContent";
import DLStoreUtilLiferayContent from "./DLStoreUtilLiferayContent";
import TimeoutLiferayContent from "./TimeoutLiferayContent";
import New74LiferayContent from "./New74LiferayContent";
import CaracteristiciLiferayContent from "./CaracteristiciLiferayContent";
import CompanyLoginPrepopulateDomainLiferayContent from "./CompanyLoginPrepopulateDomainLiferayContent";
import DataProviderLiferayContent from "./DataProviderLiferayContent";
import FireAndOnEventLiferayContent from "./FireAndOnEventLiferayContent";
import APIExplorerLiferayContent from "./APIExplorerLiferayContent";
import LiferayFormsDDMStorageAdapterLiferayContent from "./LiferayFormsDDMStorageAdapterLiferayContent";
import ClayManagementToolbarLiferayContent from "./ClayManagementToolbarLiferayContent";
import RedirectLiferayContent from "./RedirectLiferayContent";
import SaveActionLiferayContent from "./SaveActionLiferayContent";
import CleaningUpLiferayContent from "./CleaningUpLiferayContent";
import FriendlyURLLiferayContent from "./FriendlyURLLiferayContent";
import JQueryActivationLiferayContent from "./JQueryActivationLiferayContent";
import SchedulerLiferayContent from "./SchedulerLiferayContent";
import PublicServiceWebLiferayContent from "./PublicServiceWebLiferayContent";
import TabLiferayContent from "./TabLiferayContent";
import ResteasyVsHttpcomponentsLiferayContent from "./ResteasyVsHttpcomponentsLiferayContent";
import RemoteLimit30FieldsLiferayContent from "./RemoteLimit30FieldsLiferayContent";
import PreopulationFieldsLiferayContent from "./PreopulationFieldsLiferayContent";
import CustomFieldLiferayContent from "./CustomFieldLiferayContent";
import AppConfigJspLiferayContent from "./AppConfigJspLiferayContent";
import AppConfigReactLiferayContent from "./AppConfigReactLiferayContent";
import ConfigurationInstanceSettingsLiferayContent from "./ConfigurationInstanceSettingsLiferayContent";
import ServiceWebMockLiferayContent from "./ServiceWebMockLiferayContent";
import ServiceWebTestUILiferayContent from "./ServiceWebTestUILiferayContent";
import DataAccessLiferayContent from "./DataAccessLiferayContent";
import IntroductionSiteInitializersLiferayContent from "./IntroductionSiteInitializersLiferayContent";
import IntroductionSiteInitializers2LiferayContent from "./IntroductionSiteInitializers2LiferayContent";
import IntroductionSiteInitializers3LiferayContent from "./IntroductionSiteInitializers3LiferayContent";
import IntroductionSiteInitializers4LiferayContent from "./IntroductionSiteInitializers4LiferayContent";
import IntroductionSiteInitializers5LiferayContent from "./IntroductionSiteInitializers5LiferayContent";
import IntroductionSiteInitializers6LiferayContent from "./IntroductionSiteInitializers6LiferayContent";
import IntroductionSiteInitializers7LiferayContent from "./IntroductionSiteInitializers7LiferayContent";
import FragmentsDesignLiferayContent from "./FragmentsDesignLiferayContent";
import BookStylesDesignLiferayContent from "./BookStylesDesignLiferayContent";
import ApacheSSLLiferayContent from "./ApacheSSLLiferayContent";
import ImagePortletLiferayContent from "./ImagePortletLiferayContent";
import CustomDataProviderLiferayContent from "./CustomDataProviderLiferayContent";
import UpgradeProcessesLiferayContent from "./UpgradeProcessesLiferayContent";
import CustomLoginLiferayContent from "./CustomLoginLiferayContent";
import OverrideJspLiferayContent from "./OverrideJspLiferayContent";
import UserwayIntegrationLiferayContent from "./UserwayIntegrationLiferayContent";
import ChatbotIntegrationLiferayContent from "./ChatbotIntegrationLiferayContent";
import CookiesIntegrationLiferayContent from "./CookiesIntegrationLiferayContent";
import BackToTopIntegrationLiferayContent from "./BackToTopIntegrationLiferayContent";
import GoogleAnalyticsIntegrationLiferayContent from "./GoogleAnalyticsIntegrationLiferayContent";
import IntroductionSiteInitializers8LiferayContent from "./IntroductionSiteInitializers8LiferayContent";
import DisableChangePasswordFirstLoginLiferayContent from "./DisableChangePasswordFirstLoginLiferayContent";
import TermsOfUseLiferayContent from "./TermsOfUseLiferayContent";
import HideCommerceMenuLiferayContent from "./HideCommerceMenuLiferayContent";
import DisablePasswordReminderQuestionsFirstLoginLiferayContent from "./DisablePasswordReminderQuestionsFirstLoginLiferayContent";
import AddingCustomPasswordReminderQuestionsLiferayContent from "./AddingCustomPasswordReminderQuestionsLiferayContent";
import ReindexSearchLiferayContent from "./ReindexSearchLiferayContent";
import ValidationCustomFieldLiferayContent from "./ValidationCustomFieldLiferayContent";
import EmailSettingsLiferayContent from "./EmailSettingsLiferayContent";
import CustomJspBagLiferayContent from "./CustomJspBagLiferayContent";
import CustomJspBagVsOSGiFragmentsLiferayContent from "./CustomJspBagVsOSGiFragmentsLiferayContent";
import IntroductionSiteInitializers9LiferayContent from "./IntroductionSiteInitializers9LiferayContent";
import IntroductionSiteInitializers10LiferayContent from "./IntroductionSiteInitializers10LiferayContent";
import ChangingLanguageTranslationsLiferayContent from "./ChangingLanguageTranslationsLiferayContent";
import IntroductionSiteInitializers11LiferayContent from "./IntroductionSiteInitializers11LiferayContent";
import SearchContainerDeltaValuesPortletContent from "./SearchContainerDeltaValuesPortletContent";
import IntroductionSiteInitializers12LiferayContent from "./IntroductionSiteInitializers12LiferayContent";
import FormsCannotBeImportedLiferayContent from "./FormsCannotBeImportedLiferayContent";
import InvalidCENHeaderLiferayContent from "./InvalidCENHeaderLiferayContent";
import IntroductionSiteInitializers13LiferayContent from "./IntroductionSiteInitializers13LiferayContent";
import DBLiferayContent from "./DBLiferayContent";
import LinkUrlWebContentLiferayContent from "./LinkUrlWebContentLiferayContent";
import InitWithSILiferayContent from "./InitWithSILiferayContent";
import DebugLiferayContent from "./DebugLiferayContent";
import FakeSMTPEtherealEmailConfigPortletContent from "./FakeSMTPEtherealEmailConfigPortletContent";
import IntroductionSiteInitializers14LiferayContent from "./IntroductionSiteInitializers14LiferayContent";
import AnexaCreateProjectLiferayContent from "./AnexaCreateProjectLiferayContent";
import AnexaJobSchedulerLiferayContent from "./AnexaJobSchedulerLiferayContent";
import AnexaCustomConfigSystemSettingsLiferayContent from "./AnexaCustomConfigSystemSettingsLiferayContent";
import AnexaCustomStructuresSettingsLiferayContent from "./AnexaCustomStructuresSettingsLiferayContent";
import AnexaServiceBuilderLiferayContent from "./AnexaServiceBuilderLiferayContent";
import AnexaPortletLiferayContent from "./AnexaPortletLiferayContent";
import AnexaUpgradeStepsLiferayContent from "./AnexaUpgradeStepsLiferayContent";
import AnexaActivatorsLiferayContent from "./AnexaActivatorsLiferayContent";
import AnexaPLOLiferayContent from "./AnexaPLOLiferayContent";
import AnexaServletFilterLiferayContent from "./AnexaServletFilterLiferayContent";
import AnexaPortletFilterLiferayContent from "./AnexaPortletFilterLiferayContent";

/*
<Route path="/liferay/index" element={<LiferayHomeContent/>} exact/>
<Route element={<PrivateRoutes/>}>
    <Route path="/liferay/setup-intellij" element={<SetupIntelliJContent/>} exact/>
    <Route path="/liferay/first-portlet" element={<FirstPortletContent/>} exact/>
    <Route path="/liferay/deploy-module" element={<DeployModuleContent/>} exact/>
    <Route path="/liferay/first-theme" element={<CreateThemesContent/>} exact/>
    <Route path="/liferay/anatomy-theme" element={<AnatomyThemeContent/>} exact/>
    <Route path="/liferay/footer-theme" element={<FooterThemeContent/>} exact/>
    <Route path="/liferay/header-theme" element={<HeaderThemeContent/>} exact/>
    <Route path="/liferay/navigation-theme" element={<NavigationThemeContent/>} exact/>
    <Route path="/liferay/login-popup" element={<LoginPopupThemeContent/>} exact/>
    <Route path="/liferay/portlet-configuration" element={<PortletConfigurationContent/>} exact/>
    <Route path="/liferay/share-language-key-portlet" element={<ShareLanguageKeyPortletContent/>} exact/>
    <Route path="/liferay/share-language-key-theme" element={<ShareLanguageKeyThemeContent/>} exact/>
    <Route path="/liferay/keycloak" element={<KeycloakLiferayContent/>} exact/>
    <Route path="/liferay/keycloak-integration" element={<KeycloakIntegrationLiferayContent/>} exact/>
    <Route path="/liferay/keycloak-slo" element={<KeycloakSLOLiferayContent/>} exact/>
    <Route path="/liferay/change-ports" element={<ChangePortsLiferayContent/>} exact/>
    <Route path="/liferay/elasticsearch" element={<ElasticsearchLiferayContent/>} exact/>
    <Route path="/liferay/osgi" element={<OsgiLiferayContent/>} exact/>
    <Route path="/liferay/build-service" element={<BuildServiceContent/>} exact/>
    <Route path="/liferay/expando-bridge" element={<ExpandoBridgeLiferayContent/>} exact/>
    <Route path="/liferay/model-listener" element={<ModelListenersLiferayContent/>} exact/>
    <Route path="/liferay/service-wrapper" element={<ServiceWrapperLiferayContent/>} exact/>
    <Route path="/liferay/jsp" element={<JSPLiferayContent/>} exact/>
    <Route path="/liferay/i18n" element={<I18nLiferayContent/>} exact/>
    <Route path="/liferay/build-service-web" element={<ServiceWebLiferayContent/>} exact/>
    <Route path="/liferay/portlet-mvc-render-command" element={<MVCRenderCommandPortletContent/>} exact/>
    <Route path="/liferay/portlet-mvc-action-command" element={<MVCActionCommandPortletContent/>} exact/>
    <Route path="/liferay/portlet-override-mvc-action-command" element={<OverrideMVCActionCommandPortletContent/>} exact/>
    <Route path="/liferay/portlet-override-mvc-render-command" element={<OverrideMVCRenderCommandPortletContent/>} exact/>
    <Route path="/liferay/portlet-instanceable" element={<InstanceableLiferayContent/>} exact/>
    <Route path="/liferay/portlet-lifecycle" element={<LifecyclePortletContent/>} exact/>
    <Route path="/liferay/portlet-form" element={<FormPortletContent/>} exact/>
    <Route path="/liferay/portlet-search-container" element={<SearchContainerPortletContent/>} exact/>
    <Route path="/liferay/email-config" element={<EmailConfigPortletContent/>} exact/>
    <Route path="/liferay/portlet-form-validation" element={<ValidateFormPortletContent/>} exact/>
    <Route path="/liferay/portlet-forms-validation" element={<FormsValidationContent/>} exact/>
    <Route path="/liferay/localization-intro" element={<LocalizationLiferayContent/>} exact/>
    <Route path="/liferay/css" element={<CssLiferayContent/>} exact/>
    <Route path="/liferay/javascript" element={<JavascriptLiferayContent/>} exact/>
    <Route path="/liferay/react" element={<ReactLiferayContent/>} exact/>
    <Route path="/liferay/tomcat-ssl" element={<TomcatSSLLiferayContent/>} exact/>
    <Route path="/liferay/anexa-tips" element={<TipsLiferayContent/>} exact/>
    <Route path="/liferay/anexa-tips2" element={<Tips2LiferayContent/>} exact/>
    <Route path="/liferay/anexa-tips3" element={<Tips3LiferayContent/>} exact/>
    <Route path="/liferay/anexa-fragments" element={<FragmentsLiferayContent/>} exact/>
    <Route path="/liferay/widget-site-map" element={<SiteMapWidgetLiferayContent/>} exact/>
    <Route path="/liferay/widget-calendar" element={<CalendarWidgetLiferayContent/>} exact/>
    <Route path="/liferay/standards" element={<StandardsLiferayContent/>} exact/>
    <Route path="/liferay/2fa" element={<Auth2FALiferayContent/>} exact/>
    <Route path="/liferay/application-startup-events" element={<ApplicationStartupEventsLiferayContent/>} exact/>
    <Route path="/liferay/modal-ajax" element={<AjaxModalLiferayContent/>} exact/>
    <Route path="/liferay/render-html-form" element={<RenderHtmlFormLiferayContent/>} exact/>
    <Route path="/liferay/serializing-form" element={<SerializingFormsLiferayContent/>} exact/>
    <Route path="/liferay/deserializing-form" element={<DeserializingFormsLiferayContent/>} exact/>
    <Route path="/liferay/workflow" element={<WorkflowLiferayContent/>} exact/>
    <Route path="/liferay/jquery" element={<JQueryLiferayContent/>} exact/>
    <Route path="/liferay/workflow-page" element={<WorkflowPageLiferayContent/>} exact/>
    <Route path="/liferay/redirect-other-portlet" element={<RedirectOtherPortletLiferayContent/>} exact/>
    <Route path="/liferay/upload-files-ajax" element={<UploadFilesAjaxLiferayContent/>} exact/>
    <Route path="/liferay/blade" element={<BladeLiferayContent/>} exact/>
    <Route path="/liferay/dlstoreutil" element={<DLStoreUtilLiferayContent/>} exact/>
    <Route path="/liferay/timeout-session" element={<TimeoutLiferayContent/>} exact/>
    <Route path="/liferay/new-74" element={<New74LiferayContent/>} exact/>
    <Route path="/liferay/caracteristici" element={<CaracteristiciLiferayContent/>} exact/>
    <Route path="/liferay/company-login-prepopulate-domain" element={<CompanyLoginPrepopulateDomainLiferayContent/>} exact/>
    <Route path="/liferay/data-provider" element={<DataProviderLiferayContent/>} exact/>
    <Route path="/liferay/fire-and-on-event" element={<FireAndOnEventLiferayContent/>} exact/>
</Route>
 */
export default function Router() {
    let element = useRoutes([
        {path: "/liferay/index", element: <LiferayHomeContent/>},
        {path: "/liferay/setup-intellij", element: <PrivateRoute element={<SetupIntelliJContent/>}/>},
        {path: "/liferay/first-portlet", element: <PrivateRoute element={<FirstPortletContent/>}/>},
        {path: "/liferay/deploy-module", element: <PrivateRoute element={<DeployModuleContent/>}/>},
        {path: "/liferay/first-theme", element: <PrivateRoute element={<CreateThemesContent/>}/>},
        {path: "/liferay/anatomy-theme", element: <PrivateRoute element={<AnatomyThemeContent/>}/>},
        {path: "/liferay/footer-theme", element: <PrivateRoute element={<FooterThemeContent/>}/>},
        {path: "/liferay/header-theme", element: <PrivateRoute element={<HeaderThemeContent/>}/>},
        {path: "/liferay/navigation-theme", element: <PrivateRoute element={<NavigationThemeContent/>}/>},
        {path: "/liferay/login-popup", element: <PrivateRoute element={<LoginPopupThemeContent/>}/>},
        {path: "/liferay/portlet-configuration", element: <PrivateRoute element={<PortletConfigurationContent/>}/>},
        {path: "/liferay/share-language-key-portlet", element: <PrivateRoute element={<ShareLanguageKeyPortletContent/>}/>},
        {path: "/liferay/share-language-key-theme", element: <PrivateRoute element={<ShareLanguageKeyThemeContent/>}/>},
        {path: "/liferay/keycloak", element: <PrivateRoute element={<KeycloakLiferayContent/>}/>},
        {path: "/liferay/keycloak-integration", element: <PrivateRoute element={<KeycloakIntegrationLiferayContent/>}/>},
        {path: "/liferay/keycloak-slo", element: <PrivateRoute element={<KeycloakSLOLiferayContent/>}/>},
        {path: "/liferay/change-ports", element: <PrivateRoute element={<ChangePortsLiferayContent/>}/>},
        {path: "/liferay/elasticsearch", element: <PrivateRoute element={<ElasticsearchLiferayContent/>}/>},
        {path: "/liferay/osgi", element: <PrivateRoute element={<OsgiLiferayContent/>}/>},
        {path: "/liferay/build-service", element: <PrivateRoute element={<BuildServiceContent/>}/>},
        {path: "/liferay/expando-bridge", element: <PrivateRoute element={<ExpandoBridgeLiferayContent/>}/>},
        {path: "/liferay/model-listener", element: <PrivateRoute element={<ModelListenersLiferayContent/>}/>},
        {path: "/liferay/service-wrapper", element: <PrivateRoute element={<ServiceWrapperLiferayContent/>}/>},
        {path: "/liferay/jsp", element: <PrivateRoute element={<JSPLiferayContent/>}/>},
        {path: "/liferay/i18n", element: <PrivateRoute element={<I18nLiferayContent/>}/>},
        {path: "/liferay/build-service-web", element: <PrivateRoute element={<ServiceWebLiferayContent/>}/>},
        {path: "/liferay/portlet-mvc-render-command", element: <PrivateRoute element={<MVCRenderCommandPortletContent/>}/>},
        {path: "/liferay/portlet-mvc-action-command", element: <PrivateRoute element={<MVCActionCommandPortletContent/>}/>},
        {path: "/liferay/portlet-override-mvc-action-command", element: <PrivateRoute element={<OverrideMVCActionCommandPortletContent/>}/>},
        {path: "/liferay/portlet-override-mvc-render-command", element: <PrivateRoute element={<OverrideMVCRenderCommandPortletContent/>}/>},
        {path: "/liferay/portlet-instanceable", element: <PrivateRoute element={<InstanceableLiferayContent/>}/>},
        {path: "/liferay/portlet-lifecycle", element: <PrivateRoute element={<LifecyclePortletContent/>}/>},
        {path: "/liferay/portlet-form", element: <PrivateRoute element={<FormPortletContent/>}/>},
        {path: "/liferay/portlet-search-container", element: <PrivateRoute element={<SearchContainerPortletContent/>}/>},
        {path: "/liferay/email-config", element: <PrivateRoute element={<EmailConfigPortletContent/>}/>},
        {path: "/liferay/portlet-form-validation", element: <PrivateRoute element={<ValidateFormPortletContent/>}/>},
        {path: "/liferay/portlet-forms-validation", element: <PrivateRoute element={<FormsValidationContent/>}/>},
        {path: "/liferay/localization-intro", element: <PrivateRoute element={<LocalizationLiferayContent/>}/>},
        {path: "/liferay/css", element: <PrivateRoute element={<CssLiferayContent/>}/>},
        {path: "/liferay/javascript", element: <PrivateRoute element={<JavascriptLiferayContent/>}/>},
        {path: "/liferay/react", element: <PrivateRoute element={<ReactLiferayContent/>}/>},
        {path: "/liferay/tomcat-ssl", element: <PrivateRoute element={<TomcatSSLLiferayContent/>}/>},
        {path: "/liferay/anexa-tips", element: <PrivateRoute element={<TipsLiferayContent/>}/>},
        {path: "/liferay/anexa-tips2", element: <PrivateRoute element={<Tips2LiferayContent/>}/>},
        {path: "/liferay/anexa-tips3", element: <PrivateRoute element={<Tips3LiferayContent/>}/>},
        {path: "/liferay/anexa-fragments", element: <PrivateRoute element={<FragmentsLiferayContent/>}/>},
        {path: "/liferay/widget-site-map", element: <PrivateRoute element={<SiteMapWidgetLiferayContent/>}/>},
        {path: "/liferay/widget-calendar", element: <PrivateRoute element={<CalendarWidgetLiferayContent/>}/>},
        {path: "/liferay/standards", element: <PrivateRoute element={<StandardsLiferayContent/>}/>},
        {path: "/liferay/2fa", element: <PrivateRoute element={<Auth2FALiferayContent/>}/>},
        {path: "/liferay/application-startup-events", element: <PrivateRoute element={<ApplicationStartupEventsLiferayContent/>}/>},
        {path: "/liferay/modal-ajax", element: <PrivateRoute element={<AjaxModalLiferayContent/>}/>},
        {path: "/liferay/render-html-form", element: <PrivateRoute element={<RenderHtmlFormLiferayContent/>}/>},
        {path: "/liferay/serializing-form", element: <PrivateRoute element={<SerializingFormsLiferayContent/>}/>},
        {path: "/liferay/deserializing-form", element: <PrivateRoute element={<DeserializingFormsLiferayContent/>}/>},
        {path: "/liferay/workflow", element: <PrivateRoute element={<WorkflowLiferayContent/>}/>},
        {path: "/liferay/jquery", element: <PrivateRoute element={<JQueryLiferayContent/>}/>},
        {path: "/liferay/workflow-page", element: <PrivateRoute element={<WorkflowPageLiferayContent/>}/>},
        {path: "/liferay/redirect-other-portlet", element: <PrivateRoute element={<RedirectOtherPortletLiferayContent/>}/>},
        {path: "/liferay/upload-files-ajax", element: <PrivateRoute element={<UploadFilesAjaxLiferayContent/>}/>},
        {path: "/liferay/blade", element: <PrivateRoute element={<BladeLiferayContent/>}/>},
        {path: "/liferay/dlstoreutil", element: <PrivateRoute element={<DLStoreUtilLiferayContent/>}/>},
        {path: "/liferay/timeout-session", element: <PrivateRoute element={<TimeoutLiferayContent/>}/>},
        {path: "/liferay/new-74", element: <PrivateRoute element={<New74LiferayContent/>}/>},
        {path: "/liferay/caracteristici", element: <PrivateRoute element={<CaracteristiciLiferayContent/>}/>},
        {path: "/liferay/company-login-prepopulate-domain", element: <PrivateRoute element={<CompanyLoginPrepopulateDomainLiferayContent/>}/>},
        {path: "/liferay/data-provider", element: <PrivateRoute element={<DataProviderLiferayContent/>}/>},
        {path: "/liferay/fire-and-on-event", element: <PrivateRoute element={<FireAndOnEventLiferayContent/>}/>},

        {path: "/liferay/api-explorer", element: <PrivateRoute element={<APIExplorerLiferayContent/>}/>},
        {path: "/liferay/liferay-forms-ddm-storage-adapter", element: <PrivateRoute element={<LiferayFormsDDMStorageAdapterLiferayContent/>}/>},
        {path: "/liferay/clay-management-toolbar", element: <PrivateRoute element={<ClayManagementToolbarLiferayContent/>}/>},

        {path: "/liferay/redirect", element: <PrivateRoute element={<RedirectLiferayContent/>}/>},
        {path: "/liferay/save-action", element: <PrivateRoute element={<SaveActionLiferayContent/>}/>},

        {path: "/liferay/cleaning-up", element: <PrivateRoute element={<CleaningUpLiferayContent/>}/>},
        {path: "/liferay/friendly-url", element: <PrivateRoute element={<FriendlyURLLiferayContent/>}/>},
        {path: "/liferay/jquery-activation", element: <PrivateRoute element={<JQueryActivationLiferayContent/>}/>},
        {path: "/liferay/scheduler", element: <PrivateRoute element={<SchedulerLiferayContent/>}/>},

        {path: "/liferay/build-service-web-public", element: <PrivateRoute element={<PublicServiceWebLiferayContent/>}/>},

        {path: "/liferay/tab", element: <PrivateRoute element={<TabLiferayContent/>}/>},
        {path: "/liferay/resteasy-httpcomponents", element: <PrivateRoute element={<ResteasyVsHttpcomponentsLiferayContent/>}/>},

        {path: "/liferay/remove-limit-30-fields", element: <PrivateRoute element={<RemoteLimit30FieldsLiferayContent/>}/>},
        {path: "/liferay/prepopulation-fields", element: <PrivateRoute element={<PreopulationFieldsLiferayContent/>}/>},
        {path: "/liferay/custom-field", element: <PrivateRoute element={<CustomFieldLiferayContent/>}/>},

        {path: "/liferay/app-config-jsp", element: <PrivateRoute element={<AppConfigJspLiferayContent/>}/>},
        {path: "/liferay/app-config-react", element: <PrivateRoute element={<AppConfigReactLiferayContent/>}/>},

        {path: "/liferay/configuration-instance-settings", element: <PrivateRoute element={<ConfigurationInstanceSettingsLiferayContent/>}/>},

        {path: "/liferay/service-web-mock", element: <PrivateRoute element={<ServiceWebMockLiferayContent/>}/>},
        {path: "/liferay/service-web-test-ui", element: <PrivateRoute element={<ServiceWebTestUILiferayContent/>}/>},

        {path: "/liferay/data-access", element: <PrivateRoute element={<DataAccessLiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers", element: <PrivateRoute element={<IntroductionSiteInitializersLiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers-2", element: <PrivateRoute element={<IntroductionSiteInitializers2LiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers-3", element: <PrivateRoute element={<IntroductionSiteInitializers3LiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers-4", element: <PrivateRoute element={<IntroductionSiteInitializers4LiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers-5", element: <PrivateRoute element={<IntroductionSiteInitializers5LiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers-6", element: <PrivateRoute element={<IntroductionSiteInitializers6LiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers-7", element: <PrivateRoute element={<IntroductionSiteInitializers7LiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers-8", element: <PrivateRoute element={<IntroductionSiteInitializers8LiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers-9", element: <PrivateRoute element={<IntroductionSiteInitializers9LiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers-10", element: <PrivateRoute element={<IntroductionSiteInitializers10LiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers-11", element: <PrivateRoute element={<IntroductionSiteInitializers11LiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers-12", element: <PrivateRoute element={<IntroductionSiteInitializers12LiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers-13", element: <PrivateRoute element={<IntroductionSiteInitializers13LiferayContent/>}/>},
        {path: "/liferay/intro-site-initializers-14", element: <PrivateRoute element={<IntroductionSiteInitializers14LiferayContent/>}/>},


        {path: "/liferay/design-fragments", element: <PrivateRoute element={<FragmentsDesignLiferayContent/>}/>},
        {path: "/liferay/design-book-styles", element: <PrivateRoute element={<BookStylesDesignLiferayContent/>}/>},
        {path: "/liferay/apache-ssl", element: <PrivateRoute element={<ApacheSSLLiferayContent/>}/>},
        {path: "/liferay/portlet-image", element: <PrivateRoute element={<ImagePortletLiferayContent/>}/>},
        {path: "/liferay/custom-data-provider", element: <PrivateRoute element={<CustomDataProviderLiferayContent/>}/>},
        {path: "/liferay/upgrade-processes", element: <PrivateRoute element={<UpgradeProcessesLiferayContent/>}/>},

        {path: "/liferay/custom-login", element: <PrivateRoute element={<CustomLoginLiferayContent/>}/>},
        {path: "/liferay/override-jsp", element: <PrivateRoute element={<OverrideJspLiferayContent/>}/>},

        {path: "/liferay/userway", element: <PrivateRoute element={<UserwayIntegrationLiferayContent/>}/>},
        {path: "/liferay/chatbot", element: <PrivateRoute element={<ChatbotIntegrationLiferayContent/>}/>},
        {path: "/liferay/cookies", element: <PrivateRoute element={<CookiesIntegrationLiferayContent/>}/>},
        {path: "/liferay/backtotop", element: <PrivateRoute element={<BackToTopIntegrationLiferayContent/>}/>},
        {path: "/liferay/ga", element: <PrivateRoute element={<GoogleAnalyticsIntegrationLiferayContent/>}/>},
        {path: "/liferay/disable-change-password-first-login", element: <PrivateRoute element={<DisableChangePasswordFirstLoginLiferayContent/>}/>},
        {path: "/liferay/terms-of-use", element: <PrivateRoute element={<TermsOfUseLiferayContent/>}/>},
        {path: "/liferay/hide-commerce", element: <PrivateRoute element={<HideCommerceMenuLiferayContent/>}/>},
        {path: "/liferay/disable-password-reminder-questions-first-login", element: <PrivateRoute element={<DisablePasswordReminderQuestionsFirstLoginLiferayContent/>}/>},
        {path: "/liferay/adding-custom-password-reminder-questions", element: <PrivateRoute element={<AddingCustomPasswordReminderQuestionsLiferayContent/>}/>},
        {path: "/liferay/reindex", element: <PrivateRoute element={<ReindexSearchLiferayContent/>}/>},

        {path: "/liferay/validation-custom-field", element: <PrivateRoute element={<ValidationCustomFieldLiferayContent/>}/>},
        {path: "/liferay/email-settings", element: <PrivateRoute element={<EmailSettingsLiferayContent/>}/>},
        {path: "/liferay/custom-jsp-bag", element: <PrivateRoute element={<CustomJspBagLiferayContent/>}/>},
        {path: "/liferay/custom-jsp-bag-vs-osgi-fragments", element: <PrivateRoute element={<CustomJspBagVsOSGiFragmentsLiferayContent/>}/>},

        {path: "/liferay/changing-language-translations", element: <PrivateRoute element={<ChangingLanguageTranslationsLiferayContent/>}/>},
        {path: "/liferay/portlet-search-container-delta-values", element: <PrivateRoute element={<SearchContainerDeltaValuesPortletContent/>}/>},

        {path: "/liferay/forms-cannot-be-imported", element: <PrivateRoute element={<FormsCannotBeImportedLiferayContent/>}/>},
        {path: "/liferay/invalid-cen-header", element: <PrivateRoute element={<InvalidCENHeaderLiferayContent/>}/>},

        {path: "/liferay/db", element: <PrivateRoute element={<DBLiferayContent/>}/>},
        {path: "/liferay/webcontent-link-url", element: <PrivateRoute element={<LinkUrlWebContentLiferayContent/>}/>},
        {path: "/liferay/init-with-si", element: <PrivateRoute element={<InitWithSILiferayContent/>}/>},
        {path: "/liferay/debug", element: <PrivateRoute element={<DebugLiferayContent/>}/>},
        {path: "/liferay/fake-smtp-ethereal-email-config", element: <PrivateRoute element={<FakeSMTPEtherealEmailConfigPortletContent/>}/>},

        {path: "/liferay/anexa/create-project",  element: <AnexaCreateProjectLiferayContent/>},
        {path: "/liferay/anexa/job-scheduler",  element: <AnexaJobSchedulerLiferayContent/>},
        {path: "/liferay/anexa/custom-config-system-settings",  element: <AnexaCustomConfigSystemSettingsLiferayContent/>},
        {path: "/liferay/anexa/structures",  element: <PrivateRoute element={<AnexaCustomStructuresSettingsLiferayContent/>}/>},
        {path: "/liferay/anexa/service-builder",  element: <PrivateRoute element={<AnexaServiceBuilderLiferayContent/>}/>},
        {path: "/liferay/anexa/portlet",  element: <PrivateRoute element={<AnexaPortletLiferayContent/>}/>},
        {path: "/liferay/anexa/upgrade-steps",  element: <PrivateRoute element={<AnexaUpgradeStepsLiferayContent/>}/>},
        {path: "/liferay/anexa/activators",  element: <PrivateRoute element={<AnexaActivatorsLiferayContent/>}/>},
        {path: "/liferay/anexa/plo",  element: <PrivateRoute element={<AnexaPLOLiferayContent/>}/>},

        {path: "/liferay/anexa/servlet-filter",  element: <PrivateRoute element={<AnexaServletFilterLiferayContent/>}/>},
        {path: "/liferay/anexa/portlet-filter",  element: <PrivateRoute element={<AnexaPortletFilterLiferayContent/>}/>}

    ]);

    return element;
}