import {useRoutes} from "react-router-dom";
import React from "react";
import JavaScriptHomeContent from "./IndexContent";
import OverviewJavaScriptContent from "./OverviewJavaScriptContent";
import VariablesJavaScriptContent from "./VariablesJavaScriptContent";
import FunctionsJavaScriptContent from "./FunctionsJavaScriptContent";
import DataTypesJavaScriptContent from "./DataTypesJavaScriptContent";
import ComplexDataTypesJavaScriptContent from "./ComplexDataTypesJavaScriptContent";
import CommentsJavaScriptContent from "./CommentsJavaScriptContent";
import OperatorsJavaScriptContent from "./OperatorsJavaScriptContent";
import DialogsJavaScriptContent from "./DialogsJavaScriptContent";
import IfJavaScriptContent from "./IfJavaScriptContent";
import SwitchJavaScriptContent from "./SwitchJavaScriptContent";
import ConditionalOperatorJavaScriptContent from "./ConditionalOperatorJavaScriptContent";
import WhileJavaScriptContent from "./WhileJavaScriptContent";
import ForJavaScriptContent from "./ForJavaScriptContent";
import TypeConversionJavaScriptContent from "./TypeConversionJavaScriptContent";
import ErrorsJavaScriptContent from "./ErrorsJavaScriptContent";
import DebuggingJavaScriptContent from "./DebuggingJavaScriptContent";
import PushStateJavaScriptContent from "./PushStateJavaScriptContent";

{/*javascript*/
}
// <Route path="/javascript/index" element={<JavaScriptHomeContent/>} exact/>
// <Route path="/javascript/overview" element={<OverviewJavaScriptContent/>} exact/>
// <Route path="/javascript/variables" element={<VariablesJavaScriptContent/>} exact/>
// <Route path="/javascript/functions" element={<FunctionsJavaScriptContent/>} exact/>
// <Route path="/javascript/data-types" element={<DataTypesJavaScriptContent/>} exact/>
// <Route path="/javascript/complex-data-types" element={<ComplexDataTypesJavaScriptContent/>} exact/>
// <Route path="/javascript/comments" element={<CommentsJavaScriptContent/>} exact/>
// <Route path="/javascript/operators" element={<OperatorsJavaScriptContent/>} exact/>
// <Route path="/javascript/dialogs" element={<DialogsJavaScriptContent/>} exact/>
// <Route path="/javascript/if" element={<IfJavaScriptContent/>} exact/>
// <Route path="/javascript/switch" element={<SwitchJavaScriptContent/>} exact/>
// <Route path="/javascript/conditional-operator" element={<ConditionalOperatorJavaScriptContent/>} exact/>
// <Route path="/javascript/while" element={<WhileJavaScriptContent/>} exact/>
// <Route path="/javascript/for" element={<ForJavaScriptContent/>} exact/>
// <Route path="/javascript/type-conversion" element={<TypeConversionJavaScriptContent/>} exact/>
// <Route path="/javascript/errors" element={<ErrorsJavaScriptContent/>} exact/>
// <Route path="/javascript/debugging" element={<DebuggingJavaScriptContent/>} exact/>

export default function Router() {
    let element = useRoutes([
        {path: "/javascript/index", element: <JavaScriptHomeContent/>},
        {path: "/javascript/overview", element: <OverviewJavaScriptContent/>},
        {path: "/javascript/variables", element: <VariablesJavaScriptContent/>},
        {path: "/javascript/functions", element: <FunctionsJavaScriptContent/>},
        {path: "/javascript/data-types", element: <DataTypesJavaScriptContent/>},
        {path: "/javascript/complex-data-types", element: <ComplexDataTypesJavaScriptContent/>},
        {path: "/javascript/comments", element: <CommentsJavaScriptContent/>},
        {path: "/javascript/operators", element: <OperatorsJavaScriptContent/>},
        {path: "/javascript/dialogs", element: <DialogsJavaScriptContent/>},
        {path: "/javascript/if", element: <IfJavaScriptContent/>},
        {path: "/javascript/switch", element: <SwitchJavaScriptContent/>},
        {path: "/javascript/conditional-operator", element: <ConditionalOperatorJavaScriptContent/>},
        {path: "/javascript/while", element: <WhileJavaScriptContent/>},
        {path: "/javascript/for", element: <ForJavaScriptContent/>},
        {path: "/javascript/type-conversion", element: <TypeConversionJavaScriptContent/>},
        {path: "/javascript/errors", element: <ErrorsJavaScriptContent/>},
        {path: "/javascript/debugging", element: <DebuggingJavaScriptContent/>},

        {path: "/javascript/pushstate", element: <PushStateJavaScriptContent/>}

    ]);

    return element;
}