import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnexaPortletFilterLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-portlet-filter", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Portlet Filter</b>
                    <br/>
                    <br/>


                </div>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AnexaPortletFilterLiferayContent;